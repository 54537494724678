@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400&display=swap');
body{
  margin: 0px;
  padding: 0px;
}
title{
  text-transform: capitalize;
}
.header{
  display: flex;
  justify-content:space-between;
  align-items: center;
  height: 100px;
  border-bottom: 1.5px solid rgba(0,0,0,0.2);
  padding: 10px 20px;
img{
  width: 450px;
  height: auto;
}
.coustom-drop-down {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
span {
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin: 0;
  align-items: center;
  display: flex;
  margin-left: 5px;
  max-width: 40%;
@media screen and (max-width:768px){
  padding-top: 8px;
  padding-bottom: 5px;
}
}
.MuiFormControl-root {
  width: auto;
  display: block;
.MuiInputBase-root {
  width: 250px;
  float: right;
  margin-left: 10px;
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 4px;
  height: 36px;
@media screen and (max-width:1024px){
  min-width: 200px !important;
}
}
.MuiSelect-selectMenu {
  padding: 10px;
}
}
}
.chart-dropdown {
  display: flex;
  margin-right: 40px;
@media screen and (max-width: 768px) {
  flex-direction: column;
  margin: 0 !important;
}
.coustom-drop-down {
  margin-left: 10px;
@media screen and (max-width: 768px) {
  flex-direction: column;
  margin-left: 0;
  margin-bottom: 10px;
  .MuiFormControl-root {
  .MuiInputBase-root {
    min-width: 300px;
  }
}
}
}
}

.MuiMenu-paper {
.MuiMenu-list {
.MuiListItem-button {
  font-size: 14px;
  line-height: 26px;
  color: #000;
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
.Mui-selected {
  background: #eaf5fc;
  color: #2a9ada;
}
&:hover,
&:focus,
&:disabled {
   background: #eaf5fc;
   color: #2a9ada;
 }
}
}
}
.MuiInputBase-root {
.MuiSelect-select {
&:focus {
   background: transparent;
 }
}
&.MuiInput-underline {
&::before {
   border: none !important;
 }
&::after {
   border: none !important;
 }
}
}
}

.MuiDialog-container{
  position: relative !important;
}
.closeIcon{
  position: absolute !important;
  top: 10px !important;
  right: 5px !important;
}


.footer-wrapper{
  width: 100%;
  height: 90px;
  border-top: 1.5px solid rgba(0,0,0,0.2);
  display: flex;
  justify-content: space-between;
.footer-text{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px 10px 22px 10px;
.link-text{
  display: flex;
a{
  font-size: 14px;
  font-weight: normal;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.5;
  letter-spacing: normal;
  color: #2796d3;
  text-decoration: none;
&:hover{
   text-decoration: underline;
 }
}
.seprator-line{
  padding: 0px 5px 0px 5px;
  color: #2796d3;
}

}
}
p a{
  text-decoration: none;
  color: #565E78;
}
a:hover{
  text-decoration: underline;
}
p{
  font-size: 14px;
  font-weight: normal;
  font-family: 'Open Sans', sans-serif;
  line-height: 1.5;
  letter-spacing: normal;
  color:#565E78;
  margin: -58px 130px 0 0;
  text-align: right;
  margin: 0;
}
.footer-logo{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}
.footer-logo img{
  height:40px;
  width: 350px;
}
}
@media screen and (min-width:1025px) and (max-width:1440px){
  .header img{
    max-width: 375px;
  }
}


@media screen and (max-width:1024px){
  .header{
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
  }
  .footer-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 120px;
  .footer-logo{
    padding: 0px !important;
  }
  .footer-text{
    padding: 0px !important;
    padding-bottom: 10px !important;
    justify-content: center;
    align-items: center;
  }
}

.footer-wrapper p{
  margin: 0;
  font-size: 14px;
  padding-bottom: 0px !important;
}
.footer-logo{
  margin-left: 0px !important;
}
}
@media screen and(max-width:768px){
  .header img{
    max-width: 300px;
    padding-bottom: 5px;
  }
}
@media screen and (max-width:768px){
  .header{
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
    height: auto !important;
  }
}
@media screen and (max-width:425px){
  .footer-wrapper{
    height: 100px;
  p{
    font-size: 12px !important;
  }
  .link-text a{
    font-size: 12px !important;
  }
}
.footer-logo img{
  max-width: 300px;
  height: auto !important;
}
.header img{
  max-width: 300px;
  padding-bottom: 0px;
}
.logo{
  padding-bottom: 10px !important;
}


}
.feedback-wrapper{
  padding-top: 15px;
.MuiButtonBase-root{
  background-color: #1f96d3 !important;
  color: #FFF !important;
  text-transform: capitalize;
  letter-spacing: 0.55px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 16px;

}
}
.feedback-wrapper .MuiButtonBase-root{
  -moz-transform:rotate(-90deg);
  -ms-transform:rotate(-90deg);
  -o-transform:rotate(-90deg);
  -webkit-transform:rotate(-90deg);
  position: fixed;
  top:50%;
  right:-33px;
  z-index: 9999;
}
.MuiFormControl-fullWidth {
  width: 550px !important;

}

.sucess-feedback{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 12px;
h1{
  letter-spacing: 0.55px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 32px;
}
p{
  letter-spacing: 0.55px;
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-size: 14px;
}

}

.container{
img{
  width: 40px;
  height: 40px;
}

h2{
  font-size: 17px;
}
}

.loader{
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  background-color: rgba(0,0,0, 0.5);
  height: 100%;
  width: 100%;
}


.MuiFormLabel-root.Mui-focused{
 color: #2796d3 !important;
}

.MuiOutlinedInput-notchedOutline{
 border-color: #2796d3 !important;
}

.MuiButton-textPrimary{
  color: #2797d3 !important;
}

.MuiInputLabel-animated.MuiInputLabel-outlined.Mui-focused{
#outlined-multiline-static-label{
  display: none !important;
}
}

.Faqpart{
  background-color: aliceblue;
}

.Faqlink{
  text-decoration: none !important;
}

.FaqButton{
  border-color: #2797d3 !important;
  .MuiButton-label{
    padding: 0 15px;
  }
}

.FaqpartFooter{
  display: flex;
  align-items: center;
  .Faqlink{
    background-color: aliceblue;
  }
}