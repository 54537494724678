.main-wrapper{
  height: 600px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  h1{
    padding: 64px 0px 38px 38px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    color: #565E78;
    .link{
      color:#2796d3;
      text-decoration: none;
      &:hover{
        text-decoration: underline;
      }
    }
    @media screen and(max-width:425px){
      padding: 16px!important;
      font-size: 16px;
      line-height: normal;
    }
  }
  .button-wrapper{
    padding: 38px 0px 0px 38px;
    .MuiButton-outlinedPrimary{
      border: 1px solid rgba(86, 94, 120, 0.4) !important;
      border-radius: 12px !important;
    }
    .MuiButtonBase-root{
      height: 54px;
      .MuiButton-label{
        padding: 10px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 18px;
        color: #565E78;
      }
    }
    @media screen and(max-width:425px){
      padding: 16px!important;

    }
  }
  .img-wrapper{
    position: absolute;
    bottom: 0;
    right: 30px;
    @media screen and(max-width:425px){
      right: 5px;
      img{
        max-width: 350px;
        max-height: 350px;
      }
    }
    @media screen and(max-width:375px){
      img{
        max-width: 325px;
        max-height: 270px;
      }
    }
  }
  @media screen and(max-width:500px){
    align-items: center;
  }
}