.epscitynantucket-ma-frame-container{
    width:100%;
    height:2000px;
    overflow: hidden;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;
}
.epscitynantucket-ma-frame-container iframe{
    width: 100%;
    height:100%;

}
@media screen and (min-width:1025px) and (max-width:1440px){
    .epscitynantucket-ma-frame-container{
        height:2000px;
    }

}
@media screen and (min-width: 3841px) {
    .epscitynantucket-ma-frame-container{
        height:5000px;
    }
}
@media screen and (min-width: 2561px)and (max-width:3840px) {
    .epscitynantucket-ma-frame-container{
        height:5630px;
    }
}
@media screen and (min-width:2000px) and (max-width:2560px){
    .epscitynantucket-ma-frame-container{
        height:3280px;
    }

}
@media screen and (min-width:1920px) and (max-width:1999px){
    .epscitynantucket-ma-frame-container{
        height:2700px;
    }

}
@media screen and (min-width:769px) and (max-width:1024px){
    .epscitynantucket-ma-frame-container{
        height:1565px;
    }
}
/* @media screen and (max-width:768px){
    .palm-beach-frame-container{
      height:2000px !important;
  }
} */
@media screen and (min-width:601px)  and (max-width:770px){

    .epscitynantucket-ma-frame-container{
        height:1500px !important;
    }
}
@media screen and (min-width:600px)  and (max-width:425px){

    .epscitynantucket-ma-frame-container{
        height:1000px !important;
    }
}